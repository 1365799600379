import React from "react";
import "../styles/components/Footer.css";
import { FaBehance, FaTiktok, FaInstagram, FaPinterest } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import apple from "../assets/images/apple.png";
import google from "../assets/images/google.png";

export default function Footer() {
  return (
    <>
      <footer className="footer-container">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            {/* Left Section */}
            <div className="col-12 col-md-4 footer-links text-center">
              <ul className="d-flex justify-content-center gap-3">
                <li>
                  <Link to="/hiredesigners">Hire Designer</Link>
                </li>
                <li>
                  <Link to="/e-gift">eGift Cards</Link>
                </li>
                <li>
                  <Link to="/affiliate">Affiliate With US</Link>
                </li>
                <li>
                  <Link to="/sitemap">Site Map</Link>
                </li>
              </ul>
            </div>
            {/* Logo Section */}
            <div className="col-12 col-md-2 text-center mb-4 mb-md-0">
              <div className="footer-logo">
                <img
                  style={{ height: "6rem", width: "6rem" }}
                  className="d-block mx-auto"
                  src={logo}
                  alt="Company Logo"
                />
              </div>
            </div>
            {/* Right Section */}
            <div className="col-12 col-md-4 footer-links text-center">
              <ul className="d-flex justify-content-center gap-3">
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <Link to="/pressrelease">Press</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* App Store and Social Icons */}
          <div className="row d-flex justify-content-center align-items-center footer-2nd-row">
            <div className="col-6 col-md-4 text-center">
              <img
                style={{ height: "3rem", width: "9rem", marginRight: "1.3em" }}
                src={google}
                alt="Google Play Store"
              />
              <img
                style={{ height: "3rem", width: "9rem" }}
                src={apple}
                alt="Apple Store"
              />
            </div>
            <div className="col-12 col-md-4 footer-links">
              <ul className="d-flex justify-content-center gap-4">
                <li>
                  <a
                    href="http://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram style={{ fontSize: "2em" }} />
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsTwitterX style={{ fontSize: "2em" }} />
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.behance.net"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaBehance style={{ fontSize: "2em" }} />
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.tiktok.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTiktok style={{ fontSize: "2em" }} />
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.pinterest.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaPinterest style={{ fontSize: "2em" }} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center footer-3rd-row">
          <div className="col-12 footer-links text-center">
              <ul className="d-flex justify-content-center gap-3">
                <li>
                  <Link to="/privacyPolicy">Privacy Policy</Link>
                </li>
                <li>|</li>
                <li>
                  <Link to="/termsandservice">Terms and Service</Link>
                </li>
                <li>|</li>
                <li>
                  <Link to="/yourprivacychoices">Your Privacy Choices</Link>
                </li>
                <li>|</li>
                <li>
                  <Link to="/californiaprivacypolicy">California Privacy Notice</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <hr /> */}
          <div className="row">
            <div className="col-12 text-center">
              <p>
                @{new Date().getFullYear()} tekido.com Inc. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
